var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.form && Object.keys(_vm.form).length > 0
    ? _c(
        "div",
        { staticClass: "mat40" },
        [
          _vm.submitSuccess
            ? _c("PanelInfoBox", [
                _c("img", {
                  staticStyle: { "max-height": "48px" },
                  attrs: {
                    src: require("../assets/images/icons/checkmark-circle.svg"),
                  },
                }),
                _c("p", { staticClass: "text-lg" }, [
                  _c("b", [_vm._v("Dane zostały poprawnie zapisane")]),
                ]),
              ])
            : _vm._e(),
          _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Własny wygląd")]),
              ]),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c("div", { staticClass: "col-12 mab16" }, [
                          _c("p", { staticClass: "form-input__label" }, [
                            _vm._v("Ustaw kolor podstawowy"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-input__colorpicker" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.colourDefault,
                                    expression: "form.colourDefault",
                                  },
                                ],
                                attrs: { id: "coloris" },
                                domProps: { value: _vm.form.colourDefault },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "colourDefault",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Wybierz swoje logo (plik jpg lub png)"),
                            ]),
                            _c("base-input-file", {
                              attrs: {
                                isMultiple: false,
                                converter: "base64",
                                accept: "image/png, image/jpeg, image/jpg",
                                hint: "Wybierz plik",
                              },
                              model: {
                                value: _vm.files,
                                callback: function ($$v) {
                                  _vm.files = $$v
                                },
                                expression: "files",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.form.logoLink
                          ? _c(
                              "div",
                              { staticClass: "col-12 mat16" },
                              [
                                _c("p", { staticClass: "form-input__label" }, [
                                  _vm._v("Obecne logo"),
                                ]),
                                _c("img", {
                                  staticClass: "mab16",
                                  attrs: {
                                    src: _vm.form.logoLink,
                                    alt: _vm.form.logoFilename,
                                  },
                                }),
                                _c(
                                  "base-button",
                                  {
                                    attrs: { btn2: "", short: "" },
                                    on: { click: _vm.removeLogo },
                                  },
                                  [_vm._v("Usuń plik")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("div", { staticClass: "malr24" }, [
                      _c("p", { staticClass: "form-input__helper" }, [
                        _vm._v(
                          "Dostosuj wygląd systemu do własnych upodobań. Ustaw kolor podstawowy oraz kolor rozszerzony i dodaj własne logo. Dzięki temu zintegrujesz wizualnie nasz system ze swoim kolorami firmowymi."
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
            _c("div", { staticClass: "row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    { attrs: { btn1: "" }, on: { click: _vm.saveForm } },
                    [_vm._v("Zapisz")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }