var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mat40" },
    [
      _vm.submitSuccess
        ? _c("PanelInfoBox", [
            _c("img", {
              staticStyle: { "max-height": "48px" },
              attrs: {
                src: require("../assets/images/icons/checkmark-circle.svg"),
              },
            }),
            _c("p", { staticClass: "text-lg" }, [
              _c("b", [_vm._v("Dane zostały poprawnie zapisane")]),
            ]),
          ])
        : _vm._e(),
      _vm.afterSubmitInfo
        ? [
            _c("PanelInfoBox", [
              _c(
                "svg",
                {
                  attrs: {
                    width: "48",
                    height: "48",
                    viewBox: "0 0 48 48",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("g", { attrs: { id: "icon/interfaces/info-circle" } }, [
                    _c("path", {
                      attrs: {
                        id: "Shape",
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24ZM29 32H26V21C26 20.4477 25.5523 20 25 20H21C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22H24V32H21C20.4477 32 20 32.4477 20 33C20 33.5523 20.4477 34 21 34H29C29.5523 34 30 33.5523 30 33C30 32.4477 29.5523 32 29 32ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM26 15C26 14.4477 25.5523 14 25 14H23C22.4477 14 22 14.4477 22 15V17C22 17.5523 22.4477 18 23 18H25C25.5523 18 26 17.5523 26 17V15Z",
                        fill: "#455A64",
                      },
                    }),
                  ]),
                ]
              ),
              _c("b", [
                _vm._v(
                  "Aby skończyc rejestracje przejdź do serwisu Przelewy 24: "
                ),
                _c(
                  "a",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      "text-decoration": "underline",
                    },
                    attrs: {
                      href: _vm.afterSubmitResponse.data.link,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(_vm._s(_vm.afterSubmitResponse.data.link))]
                ),
              ]),
            ]),
          ]
        : _vm.organizer && Object.keys(_vm.organizer).length > 0
        ? [
            _vm.operatorId === null &&
            _vm.organizer.subjectPayment &&
            _vm.operator &&
            _vm.operator.p24On === true
              ? _c(
                  "panel-competition-form-box",
                  { staticClass: "panel-form" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("h2", [
                          _vm._v("Pobieranie płatności online od uczestników"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("p", [
                          _vm._v(
                            "Płatności za zawody od uczestników zbiera w Twoim imieniu operator:"
                          ),
                        ]),
                        _c("br"),
                        _c("p", [
                          _c("b", [_vm._v(_vm._s(_vm.operator.name))]),
                          _c("br"),
                          _vm.operator.phone
                            ? _c("span", [
                                _vm._v("tel. " + _vm._s(_vm.operator.phone)),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.operator.email
                            ? _c("span", [_vm._v(_vm._s(_vm.operator.email))])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : [
                  _vm.operatorId === null
                    ? _c("PanelInfoBox", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "48",
                              height: "48",
                              viewBox: "0 0 48 48",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c(
                              "g",
                              { attrs: { id: "icon/interfaces/info-circle" } },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Shape",
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24ZM29 32H26V21C26 20.4477 25.5523 20 25 20H21C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22H24V32H21C20.4477 32 20 32.4477 20 33C20 33.5523 20.4477 34 21 34H29C29.5523 34 30 33.5523 30 33C30 32.4477 29.5523 32 29 32ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM26 15C26 14.4477 25.5523 14 25 14H23C22.4477 14 22 14.4477 22 15V17C22 17.5523 22.4477 18 23 18H25C25.5523 18 26 17.5523 26 17V15Z",
                                    fill: "#455A64",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("b", [
                          _vm._v(
                            "Aby rozpocząć pobieranie opłat online od uczestników, zdecyduj czy wolisz użyć własnego konta w Przelewy 24, czy zlecić pobieranie opłat dostartu."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "panel-competition-form-box",
                    { staticClass: "panel-form" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("h2", [
                            _vm._v(
                              "Pobieranie płatności online od uczestników"
                            ),
                          ]),
                          _vm.operatorPaymentsOn
                            ? _c("small", [_vm._v("Ustawienia operatora")])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm.operatorPaymentsOn
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6" },
                              [
                                _c("panel-input-base-radio", {
                                  attrs: {
                                    name: "startlimit",
                                    option: {
                                      value: "subjectPayment",
                                      title: "Operator zbiera płatności",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.startlimit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "startlimit", $$v)
                                    },
                                    expression: "form.startlimit",
                                  },
                                }),
                                _c("div", { staticClass: "field-optional" }, [
                                  _c("p", [
                                    _vm._v("Stała prowizja od każdych zawodów"),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("p", { staticClass: "field-helper mal32" }, [
                                _vm._v(
                                  "Będziesz pobierać opłaty online na rzecz organizatora i rozliczać się z organizatorami we własnym zakresie."
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("panel-input-base-radio", {
                              attrs: {
                                name: "startlimit",
                                option: {
                                  value: "st1",
                                  title: _vm.operatorPaymentsOn
                                    ? "Dostartu zbiera płatności"
                                    : "Zlecam pobieranie opłat",
                                },
                              },
                              model: {
                                value: _vm.form.startlimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "startlimit", $$v)
                                },
                                expression: "form.startlimit",
                              },
                            }),
                            _c("div", { staticClass: "field-optional" }, [
                              _c("p", [
                                _vm._v(
                                  "6,0% prowizji od pobieranych płatności"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _vm.operatorPaymentsOn
                            ? _c("p", { staticClass: "field-helper mal32" }, [
                                _vm._v(
                                  "Będziemy pobierać opłaty w imieniu organizatora, a "
                                ),
                                _c("b", [_vm._v("po zakończeniu zawodów")]),
                                _vm._v(
                                  ", zebrane środki przelejemy na wskazana przez organizatora konto"
                                ),
                              ])
                            : _c("p", { staticClass: "field-helper mal32" }, [
                                _vm._v(
                                  "Będziemy pobierać opłaty w Twoim imieniu, a "
                                ),
                                _c("b", [_vm._v("po zakończeniu zawodów")]),
                                _vm._v(
                                  ", zebrane środki przelejemy na wskazana przez Ciebie konto"
                                ),
                              ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("panel-input-base-radio", {
                              attrs: {
                                name: "startlimit",
                                option: {
                                  value: "st2",
                                  title: _vm.operatorPaymentsOn
                                    ? "Organizator zbiera płatności"
                                    : "Zbieram płatności",
                                },
                              },
                              model: {
                                value: _vm.form.startlimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "startlimit", $$v)
                                },
                                expression: "form.startlimit",
                              },
                            }),
                            _c("div", { staticClass: "field-optional" }, [
                              _vm.organizer?.operator === 1
                                ? _c("p", [
                                    _vm._v(
                                      "3,6% prowizji od pobieranych płatności"
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v("Stała prowizja od każdych zawodów"),
                                  ]),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _vm.operatorPaymentsOn
                            ? _c("p", { staticClass: "field-helper mal32" }, [
                                _vm._v(
                                  "Organizator będzie pobierać opłaty online we własnym zakresie. Może użyć istniejącego konta w "
                                ),
                                _c("b", [_vm._v("Przelewy 24")]),
                                _vm._v(" lub założyć nowe konto."),
                              ])
                            : _vm._e(),
                          _c("p", { staticClass: "field-helper mal32" }, [
                            _vm._v(
                              "Będziesz pobierać opłaty online we własnym zakresie. Możesz użyć istniejącego konta w "
                            ),
                            _c("b", [_vm._v("Przelewy 24")]),
                            _vm._v(" lub założyć nowe konto w kolejnym kroku."),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.form.startlimit === "st1"
                    ? [
                        _c("ValidationObserver", {
                          ref: "orderCollectionOfFeesFormObserver",
                          staticStyle: { "margin-top": "16px" },
                          attrs: { tag: "div" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ handleSubmit }) {
                                  return [
                                    _c(
                                      "form",
                                      {
                                        class: { "is-waiting": _vm.isWaiting },
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return handleSubmit(
                                              _vm.orderCollectionOfFees
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "panel-competition-form-box",
                                          { staticClass: "panel-form" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6",
                                                },
                                                [
                                                  _c("ValidationProvider", {
                                                    staticClass:
                                                      "scroll-margin",
                                                    attrs: {
                                                      name: "collect.bankAccount",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "panel-input-text",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Numer konta",
                                                                    error:
                                                                      errors[0],
                                                                    hint: "Wpisz nazwe",
                                                                  },
                                                                  on: {
                                                                    "update:error":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          errors,
                                                                          0,
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .collect
                                                                        .bankAccount,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.collect,
                                                                          "bankAccount",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "collect.bankAccount",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "field-helper mal32",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Podaj numer konta, na które chcesz otrzymać zebrane od uczestników opłaty. Środki przelejemy po zakończeniu zawodów."
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.organizer.payAgreements
                                          ? _c(
                                              "panel-competition-form-box",
                                              { staticClass: "panel-form" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              "text-decoration":
                                                                "underline",
                                                            },
                                                            attrs: {
                                                              href: `https://dostartu.pl/${_vm.organizer.payAgreements}`,
                                                              target: "_blank",
                                                              rel: "noopener noreferrer",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Zawarta umowa na zlecenie pobierania opłat"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "panel-competition-form-box",
                                              { staticClass: "panel-form" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm._v(
                                                          " 1. Pobierz umowę "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "info-box",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "Umowa na zlecenie pobierania opłat przez dostartu"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "info-box-button",
                                                                attrs: {
                                                                  href: "https://dostartu.pl/files/panel/umowa_platnosci.pdf",
                                                                  target:
                                                                    "_blank",
                                                                  rel: "noopener noreferrer",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Pobierz umowę"
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-file-download",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm._v(
                                                          "2. Wydrukuj i podpisz umowę"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm._v(
                                                          " 3. Wgraj skan podpisanej umowy "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "collect.file",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "none",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Numer konta",
                                                                              error:
                                                                                errors[0],
                                                                              hint: "Wpisz nazwe",
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-input-file",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "is-error":
                                                                                errors[0],
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              accept:
                                                                                "image/*,.pdf",
                                                                              isMultiple: false,
                                                                              converter:
                                                                                "base64",
                                                                              hint: "Wybierz plik",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .collect
                                                                                  .file,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.collect,
                                                                                    "file",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "collect.file",
                                                                            },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "input-error-alt",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Pole wymagane"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1074483782
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _c(
                    "panel-competition-form-box",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.startlimit === "st2",
                          expression: "form.startlimit === 'st2'",
                        },
                      ],
                      staticClass: "panel-form",
                    },
                    [
                      _c("h4", [
                        _vm._v("Konto "),
                        _c("b", [_vm._v("Przelewy 24")]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("panel-input-base-radio", {
                              attrs: {
                                name: "form.p24",
                                option: {
                                  value: "12",
                                  title: "Posiadam konto w Przelewy24",
                                },
                              },
                              model: {
                                value: _vm.form.p24,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "p24", $$v)
                                },
                                expression: "form.p24",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("panel-input-base-radio", {
                              attrs: {
                                name: "form.p24",
                                option: {
                                  value: "22",
                                  title: "Chcę założyć konto w Przelewy24",
                                },
                              },
                              model: {
                                value: _vm.form.p24,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "p24", $$v)
                                },
                                expression: "form.p24",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("panel-input-base-radio", {
                              attrs: {
                                name: "form.p24",
                                option: {
                                  value: "32",
                                  title:
                                    "Posiadam stare konto Dotpay (ważne do 30.09.2024)",
                                },
                              },
                              model: {
                                value: _vm.form.p24,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "p24", $$v)
                                },
                                expression: "form.p24",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm.form.startlimit === "st2" && _vm.form.p24 === "12"
                    ? _c(
                        "panel-competition-form-box",
                        { staticClass: "panel-form" },
                        [
                          _c("ValidationObserver", {
                            ref: "putOrganizerFormObserver",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c("h4", [
                                        _vm._v("Moje konto przelewy24"),
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _c(
                                              "form",
                                              {
                                                class: {
                                                  "is-waiting": _vm.isWaiting,
                                                },
                                                on: {
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                    return handleSubmit(
                                                      _vm.putOrganizerSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("ValidationProvider", {
                                                  staticClass: "scroll-margin",
                                                  attrs: {
                                                    name: "p24put.p24Id",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "panel-input-text",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Numer ID klienta Przelewy24",
                                                                  error:
                                                                    errors.p24Id,
                                                                },
                                                                on: {
                                                                  "update:error":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        errors,
                                                                        "p24Id",
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.p24put
                                                                      .p24Id,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.p24put,
                                                                        "p24Id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "p24put.p24Id",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "field-helper mal32",
                                              },
                                              [
                                                _vm._v(
                                                  "Numer ID klienta znajdziesz w ustawieniach panelu systemu Przelewy 24, w sekcji Konfiguracja urlc."
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3406852559
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.startlimit &&
                  _vm.form.startlimit === "st2" &&
                  _vm.form.p24 &&
                  _vm.form.p24 === "22"
                    ? _c("ValidationObserver", {
                        ref: "newMerchantFormObserver",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ handleSubmit }) {
                                return [
                                  _c(
                                    "form",
                                    {
                                      class: { "is-waiting": _vm.isWaiting },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return handleSubmit(
                                            _vm.newMerchantSubmit
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "panel-competition-form-box",
                                        { staticClass: "panel-form mat16" },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              "Rejestracja konta Przelewy24"
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "panel-form col-12 col-md-6",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.business_type",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              selectLabel:
                                                                                "Rodzaj działalności",
                                                                              options:
                                                                                _vm.p24BusinessType,
                                                                              "hide-selected": false,
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .business_type,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "business_type",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.business_type",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm.p24form.business_type &&
                                                _vm.p24form.business_type
                                                  .value === 1
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                staticClass:
                                                                  "scroll-margin",
                                                                attrs: {
                                                                  name: "p24form.pesel",
                                                                  rules: {
                                                                    required:
                                                                      _vm
                                                                        .p24form
                                                                        .business_type &&
                                                                      _vm
                                                                        .p24form
                                                                        .business_type
                                                                        .value ===
                                                                        1
                                                                        ? true
                                                                        : false,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "panel-input-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Pesel",
                                                                                    error:
                                                                                      errors[0],
                                                                                  },
                                                                                on: {
                                                                                  "update:error":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        errors,
                                                                                        0,
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .p24form
                                                                                        .pesel,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.p24form,
                                                                                          "pesel",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "p24form.pesel",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.p24form.business_type &&
                                                _vm.p24form.business_type
                                                  .value > 3
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                staticClass:
                                                                  "scroll-margin",
                                                                attrs: {
                                                                  name: "p24form.krs",
                                                                  rules: {
                                                                    required:
                                                                      _vm
                                                                        .p24form
                                                                        .business_type &&
                                                                      _vm
                                                                        .p24form
                                                                        .business_type
                                                                        .value >
                                                                        3
                                                                        ? true
                                                                        : false,
                                                                    min: 10,
                                                                    max: 10,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "panel-input-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "number",
                                                                                    label:
                                                                                      "KRS",
                                                                                    error:
                                                                                      errors[0],
                                                                                  },
                                                                                on: {
                                                                                  "update:error":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        errors,
                                                                                        0,
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .p24form
                                                                                        .krs,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.p24form,
                                                                                          "krs",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "p24form.krs",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.p24form.business_type &&
                                                _vm.p24form.business_type
                                                  .value !== 1
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                staticClass:
                                                                  "scroll-margin",
                                                                attrs: {
                                                                  name: "p24form.nip",
                                                                  rules: {
                                                                    required:
                                                                      _vm
                                                                        .p24form
                                                                        .business_type &&
                                                                      _vm
                                                                        .p24form
                                                                        .business_type
                                                                        .value !==
                                                                        1
                                                                        ? true
                                                                        : false,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "panel-input-text",
                                                                              {
                                                                                staticClass:
                                                                                  "mab8",
                                                                                attrs:
                                                                                  {
                                                                                    type: "number",
                                                                                    label:
                                                                                      "NIP",
                                                                                    error:
                                                                                      errors[0],
                                                                                  },
                                                                                on: {
                                                                                  "update:error":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        errors,
                                                                                        0,
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .p24form
                                                                                        .nip,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.p24form,
                                                                                          "nip",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "p24form.nip",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                staticClass:
                                                                  "scroll-margin",
                                                                attrs: {
                                                                  name: "p24form.regon",
                                                                  rules: {
                                                                    required:
                                                                      _vm
                                                                        .p24form
                                                                        .business_type &&
                                                                      _vm
                                                                        .p24form
                                                                        .business_type
                                                                        .value !==
                                                                        1
                                                                        ? true
                                                                        : false,
                                                                    min: 9,
                                                                    max: 14,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "panel-input-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "number",
                                                                                    label:
                                                                                      "Regon",
                                                                                    error:
                                                                                      errors[0],
                                                                                  },
                                                                                on: {
                                                                                  "update:error":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        errors,
                                                                                        0,
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .p24form
                                                                                        .regon,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.p24form,
                                                                                          "regon",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "p24form.regon",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.name",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Nazwa firmy / organizacji",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .name,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "name",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.name",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.email",
                                                              rules:
                                                                "required|email",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "E-mail",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .email,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "email",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.email",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.phoneNumber",
                                                              rules:
                                                                "required|min:9|max:9",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              label:
                                                                                "Telefon",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .phoneNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "phoneNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.phoneNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.bankAccount",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              label:
                                                                                "Numer konta",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .bankAccount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "bankAccount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.bankAccount",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.street",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Ulica",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .street,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "street",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.street",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.postCode",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Kod pocztowy",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .postCode,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "postCode",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.postCode",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.city",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Miejscowość",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .city,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "city",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.city",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "row",
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "p24form.country",
                                                              rules: "required",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-text",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Kraj",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .p24form
                                                                                  .country,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.p24form,
                                                                                    "country",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "p24form.country",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-md-6",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "field-helper mal32",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Poniższe dane wymagane są wyłącznie do zawarcia umowy z operatorem płatności "
                                                    ),
                                                    _c("b", [
                                                      _vm._v("Przelewy24"),
                                                    ]),
                                                    _vm._v("."),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "field-helper mal32",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Dane zostaną bezpośrednio przekazane firmie "
                                                    ),
                                                    _c("b", [
                                                      _vm._v("Przelewy24"),
                                                    ]),
                                                    _vm._v(
                                                      " i nie będą zapisane w systemie DOSTARTU."
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "field-helper mal32",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Prosimy o podanie danych zgodnych z dokumentami rejestracyjnymi Twojej firmy."
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "panel-competition-form-box",
                                        { staticClass: "panel-form" },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              "Osoba reprezentująca firmę / organizację"
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "panel-form col-12 col-md-6",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label:
                                                              "Imię i nazwisko",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactPersonName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactPersonName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactPersonName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label: "E-mail",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactPersonEmail,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactPersonEmail",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactPersonEmail",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label: "Telefon",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactPersonPhoneNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactPersonPhoneNumber",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactPersonPhoneNumber",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "row",
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label: "Kraj",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactCountry,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactCountry",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactCountry",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label: "Miasto",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactCity,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactCity",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactCity",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label:
                                                              "Kod pocztowy",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactPostCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactPostCode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactPostCode",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c("panel-input-text", {
                                                          attrs: {
                                                            label: "Ulica",
                                                            error:
                                                              _vm.errors.name,
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.errors,
                                                                  "name",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.p24form
                                                                .contactStreet,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.p24form,
                                                                "contactStreet",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "p24form.contactStreet",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "input_ids_add_1",
                                                              rules: {
                                                                required: {
                                                                  allowFalse: false,
                                                                },
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "checkbox-value":
                                                                                {
                                                                                  value: 1,
                                                                                  text: "Akceptuje Regulamin korzystania z usług Przelewy24 na platformie DOSTARTU",
                                                                                },
                                                                              name: `input_ids_add_1`,
                                                                              variant:
                                                                                "white",
                                                                              value: 0,
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .input_ids_add_1,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "input_ids_add_1",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.input_ids_add_1",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Akceptuje Regulamin korzystania z usług Przelewy24 na platformie DOSTARTU "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name: "input_ids_add_2",
                                                              rules: {
                                                                required: {
                                                                  allowFalse: false,
                                                                },
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "checkbox-value":
                                                                                {
                                                                                  value: 2,
                                                                                  text: "Jestem uprawniony do reprezentowania powyższego podmiotu",
                                                                                },
                                                                              name: `input_ids_add_2`,
                                                                              variant:
                                                                                "white",
                                                                              error:
                                                                                errors[0],
                                                                            },
                                                                          on: {
                                                                            "update:error":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  errors,
                                                                                  0,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .input_ids_add_2,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "input_ids_add_2",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.input_ids_add_2",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Jestem uprawniony do reprezentowania powyższego podmiotu "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1823571896
                        ),
                      })
                    : _vm._e(),
                  _vm.form.startlimit === "st2" && _vm.form.p24 === "32"
                    ? _c(
                        "panel-competition-form-box",
                        { staticClass: "panel-form" },
                        [
                          _c("ValidationObserver", {
                            ref: "putOrganizerFormObserver",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c("h4", [
                                        _vm._v(
                                          "Podaj numer aktualnie działającego konta Dotpay"
                                        ),
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _c(
                                              "form",
                                              {
                                                class: {
                                                  "is-waiting": _vm.isWaiting,
                                                },
                                                on: {
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                    return handleSubmit(
                                                      _vm.putOrganizerSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("ValidationProvider", {
                                                  staticClass: "scroll-margin",
                                                  attrs: {
                                                    name: "dotpayput.dotpayId",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "panel-input-text",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Numer ID klienta Dotpay",
                                                                  error:
                                                                    errors.dotpayId,
                                                                },
                                                                on: {
                                                                  "update:error":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        errors,
                                                                        "dotpayId",
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .dotpayput
                                                                      .dotpayId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.dotpayput,
                                                                        "dotpayId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "dotpayput.dotpayId",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-md-6" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "field-helper mal32",
                                              },
                                              [
                                                _vm._v(
                                                  "Konto Dotpay działa do 30.09.2024. Warto założyć konto P24 wcześniej a nadal używać Dotpay aż do zakończenia współpracy."
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2826940220
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.startlimit === "subjectPayment"
                    ? _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
                        _c("div", { staticClass: "row justify-content-end" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-4" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: { btn1: "", short: "" },
                                  on: { click: _vm.orderSubjectPaymentsSubmit },
                                },
                                [_vm._v("Zapisz")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : [
                        _vm.form.startlimit === "st1"
                          ? _c(
                              "div",
                              { staticClass: "col-lg-12 mat16 mab64" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-end" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-4" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            attrs: { btn1: "", short: "" },
                                            on: {
                                              click:
                                                _vm.orderCollectionOfFeesSubmit,
                                            },
                                          },
                                          [_vm._v("Zapisz")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.form.startlimit === "st2" && _vm.form.p24 === "12"
                          ? _c(
                              "div",
                              { staticClass: "col-lg-12 mat16 mab64" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-end" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-4" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            attrs: { btn1: "", short: "" },
                                            on: {
                                              click: _vm.putOrganizerSubmit,
                                            },
                                          },
                                          [_vm._v("Zapisz")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.form.startlimit === "st2" && _vm.form.p24 === "22"
                          ? _c(
                              "div",
                              { staticClass: "col-lg-12 mat16 mab64" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-end" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-4" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            attrs: { btn1: "", short: "" },
                                            on: {
                                              click: _vm.newMerchantSubmit,
                                            },
                                          },
                                          [_vm._v("Zapisz")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.form.startlimit === "st2" && _vm.form.p24 === "32"
                          ? _c(
                              "div",
                              { staticClass: "col-lg-12 mat16 mab64" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row justify-content-end" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-4" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            attrs: { btn1: "", short: "" },
                                            on: {
                                              click: _vm.putOrganizerSubmit,
                                            },
                                          },
                                          [_vm._v("Zapisz")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }